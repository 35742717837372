import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      showBtn: false,
      width: window.innerWidth,
      showMenuModal: false,
      tagId: '',
      data: {},
    };
  },
  isPopup() {
    return true;
  },

  beforeDestroy(){
    const documentObj = document.querySelector('#ag-grid-id > div:nth-child(3) > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-root.ag-unselectable.ag-layout-normal > div.ag-body-viewport.ag-layout-normal.ag-row-no-animation')
    if(documentObj){
      documentObj.removeEventListener(
        "scroll",
        () => this.runOnScroll()
      );
    }

  },
  created() {
    if (this.width < 700) {
      this.showBtn = true;
    }
    // console.log(this.params)
    const objectID = this.params.data.email;
    this.tagID = `${objectID}Tag`;
    const gridApi = this.params.api;
    this.data = gridApi.getRowNode(objectID).data;
    const documentObj = document.querySelector('#ag-grid-id > div:nth-child(3) > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-root.ag-unselectable.ag-layout-normal > div.ag-body-viewport.ag-layout-normal.ag-row-no-animation')
    documentObj.addEventListener(
      "scroll",
      () => this.runOnScroll(),
      { passive: true }
    );
  },
  methods: {

    runOnScroll(){
      this.showMenuModal = false;
    },
    editUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.editUserRecord(data);
      this.showMenuModal = false;

    //   this.params.api.updateRowData({ remove: [rowNode ] });
    },

    deleteUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.deleteUserRecord(data);
      this.showMenuModal = false;
    },

    enableUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.enableUserRecord(data);
      this.showMenuModal = false;
    },

    openActionMenu() {
      this.showMenuModal = !this.showMenuModal;
      // console.log(this.$refs)
      const element = this.$refs.buttonActionMenu;
      // console.log(element)

      // console.log(element)
      // ms.$refs.list.style.width = `${this.$el.clientWidth}px`;

      // ms.$refs.list.style.position = 'fixed';
      // let element = document.querySelector(`${this.tagID} > .multiselect__content-wrapper`)
      // let parent = document.getElementById(this.tagID)
      // console.log(element)
      if (element) {
        const dropdown = this.$refs['buttonActionDialog'];
        // console.log(dropdown)
        const cor = element.getBoundingClientRect();
        const top = cor.top
        if (top > 50) {
          dropdown.style.top = `${top}px`;
        }
      }
    },
    closeActionMenu() {
      // console.log('close')
      this.showMenuModal = false;
    },
    hel(){
     
    }

  },
  template: `
      <div  :id="tagID">

      <span
     
      ref="buttonActionMenu"
      @click="openActionMenu"
       style="display: inline-block;  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
    more_vert
    </span>

    <div
    ref="buttonActionDialog"
    v-closable="{
      exclude: ['buttonActionMenu'],
      handler: 'closeActionMenu'
    }"
    v-show="showMenuModal"
    class="fixed z-50 w-40 -mt-6  mr-2 px-2 space-y-1 transform bg-white rounded-md right-0 translate-x-1
      min-w-max bg-charcoal shadow-lg"
  >
    <ul class="grid my-2">
      <li @click="editUserRecord()">
        <a
          class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
            hover:border-cyan hover:bg-gray-500 border-transparent"
        >
         Edit
        </a>
      </li>
      <li v-if="data.active === 'Active'" @click="deleteUserRecord">
        <a
          class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
            hover:border-cyan hover:bg-gray-500 border-transparent"
        >
         Disable
        </a>
      </li>
      <li v-else @click="enableUserRecord">
      <a
        class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
          hover:border-cyan hover:bg-gray-500 border-transparent"
      >
       Enable
      </a>
    </li>
    </ul>
  </div>
    </div>
    `,
});
