












































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import scheduleModule from '@/store/modules/scheduleModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import userProfileModule from '@/store/modules/userProfileModule';
import cellRender from './agGrid/CellActionRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './agGrid/header.js';
import EditableHeader from './agGrid/headerEditable.js';
import TagSelect from './agGrid/userTagCell.vue';
import DateSelect from './userDateCell.vue';
import UserSelect from './userSelectCell.vue';

@Component({
  components: {
    UserSettings: () => getComponent('common/UserSettings'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    agColumnEdiable: EditableHeader,
    UserSelect,
    Multiselect,
    TagSelect,
    DateSelect,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   newGroupName = '';

   showModal = false;

  showUserModal =false;

  user: any = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    role: '',
    team: '',
  };

   userDefault: any = {
     firstName: '',
     lastName: '',
     phoneNumber: '',
     email: '',
     role: '',
     team: '',
   };

   async postNewUser() {
    //  this.dataLoading = true;
     if (this.user.id) {
       await this.updateUserDetail();
     } else {
       await userProfileModule.postNewAdminUser(this.user);
     }
     this.cancelButtonTapped();
       this.dataLoading = false;
   
     await this.fetchData();
        // window.location.reload();
   
   }

   async updateUserDetail() {
     await userProfileModule.updateUserDetails(this.user);
     this.cancelButtonTapped();
     //  window.location.reload();
   }

   cancelButtonTapped() {
     this.showUserModal = false;
     this.user = this.userDefault;
   }

  activeDeleteRowWellName= '';

  diableUserPopup = false;

  hideAgGrid: any = false;

  availableWellNames = [];

  dataLoading = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

  dropdownLocation: any = []

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   selectedWell: any = ''

   columnDefs: any = [

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    cellClick(e) {
      // console.log(e);
    }

    get isMobile() {
      return this.windowWidth < 769;
    }

    async stopEdit() {
      await this.saveGroupDetailsToBackend();
    }

    closeDialog() {
      this.hideAgGrid = true;
      // console.log('close');
      this.showModal = false;
      setTimeout(() => {
        this.hideAgGrid = false;
      }, 300);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = [];
      this.columnDefs = [];
      this.gridColumnApi = params.columnApi;
      const columns = [];
      this.gridApi.hideOverlay();

      this.columnDefs = [
        {
          field: 'name',
          cellClass: 'noborder',
          cellStyle: { border: 'none' },
          sortable: true,
          filter: true,
          width: 200,
          editable: false,
          checkboxSelection: false,
          colId: 'Name',

          headerName: 'Name',
        },
        {
          field: 'email',
          editable: false,
          colId: 'Email',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Email',
        },
        {
          field: 'phoneNumber',
          editable: false,
          width: 200,
          colId: 'phoneNumber',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Phone',
        },
        {
          field: 'role',
          editable: false,
          width: 200,
          colId: 'role',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Role',
        },
        {
          field: 'userAdminAccess',
          editable: false,
          width: 200,
          colId: 'userAdminAccess',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Admin Access',
        },
        {
          field: 'team',
          editable: false,
          width: 180,
          colId: 'Team',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Team',
        },
        {
          field: 'area',
          editable: false,
          width: 180,
          colId: 'Area',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Area',
        },
        {
          field: 'active',
          editable: false,
          width: 180,
          colId: 'status',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Status',
        },
        {
          field: 'defaultPage',
          editable: false,
          width: 180,
          colId: 'defaultPage',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Default Page',
        },
        {
          field: 'adminChartAccess',
          editable: false,
          width: 180,
          colId: 'adminChartAccess',
          cellStyle: { border: 'none', overflow: 'visible' },
          cellClass: 'noborder',
          headerName: 'Chart Access',
        },
        {
          field: 'routes',
          editable: false,
          colId: 'routes',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          initialWidth: 520,
          cellStyle: { 'text-overflow': 'ellipses', 'white-space': 'nowrap' },

          wrapText: true,
          cellClass: 'noborder',
          headerName: 'Routes',
        },

        {
          field: 'Actions',
          colId: 'actions',
          sortable: true,
          pinned: 'right',
          cellClass: 'noborder',
          suppressToolPanel: true,
          cellStyle: { border: 'none' },
          suppressRowTransform: true,

          editable: false,

          initialWidth: 120,
          cellRendererFramework: 'cellRender',
          cellRendererParams: {
            clicked(field) {
              alert(`${field} was clicked`);
            },
          },
        },
      ];
    }

    async confirmDisableUser() {
      this.diableUserPopup = false;
      await workflowModule.deleteUser(this.user);
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: false,
	  });

      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: false,
	  });
      // await workflowModule.updateUserActiveStatus(this.user)
      this.user = this.userDefault;
      window.location.reload();
      await this.sleep(5000);
      this.cancelButtonTapped();
      await this.fetchData();
    }

    async cancelDiableUser() {
      this.user = this.userDefault;
      this.diableUserPopup = false;
    }

    async enableUserRecord(data) {
      this.user = data;
      this.diableUserPopup = false;
      // await workflowModule.deleteUser(this.user);
      await workflowModule.updateUserActiveStatus(this.user);
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: true,
	  });
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: true,
	  });
      this.user = this.userDefault;
      // await this.sleep(5000);
      window.location.reload();
      this.cancelButtonTapped();
      await this.fetchData();
      //  await this.fetchData();
    }

    async saveGroupDetailsToBackend() {
      // const updatedObject = {
      //   group_id: this.activeGroup.ID,
      //   columns: JSON.stringify(this.activeGroup.Columns),
      //   payload: JSON.stringify(this.rowData),
      // };

      // await scheduleModule.updateGroupDetails(updatedObject);
    }

    getDefaultPage(role) {
      let defaultPage = 'Tasqs';
      if (role === 'Engineers') {
        defaultPage = 'TasqsKanban';
      } else if (role === 'Mangers') {
        defaultPage = 'Report';
      }
      return defaultPage;
    }

    beforeMount() {
      this.context = { componentParent: this };
      // @ts-ignore
      window.gridApiVue = this;
    }

    async editUserRecord(data) {
      // this.rowData[index][fieldName] = data;
      this.user = data;
      this.showUserModal = true;
      // console.log( this.rowData[index][fieldName])
    }

    async deleteUserRecord(data) {
      // this.rowData[index][fieldName] = data;
      this.user = data;
      this.diableUserPopup = true;
      // console.log( this.rowData[index][fieldName])
    }

    get teams() {
	   const structuredTeams: any = [];
	   for (let x = 0; x < workflowModule.teams.length; x++) {
		   structuredTeams.push({ text: workflowModule.teams[x], value: workflowModule.teams[x] });
	   }

      return structuredTeams;
    }

    get defaultPages() {
	   return [
        'Groups',
        'Operations',
        'TasqList-Pad',
        'TasqList-Well',
        'TasqsKanban',
        'UserReport',
      ];
    }

    async created() {
      this.dataLoading = true;
      this.getRowNodeId = (data) => data.email;
      await workflowModule.listTeams();
      await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
      //  console.log(workflowModule.user)
      if (workflowModule.user.role !== 'Engineers' && !workflowModule.user.userAdminAccess) {
        this.$router.push({
          name: 'Home',
        });
      }
      // await assetsModule.getEnabledWells();
      this.dataLoading = false;
      await this.fetchData();
    }

    get allUsersList() {
      return accountModule.allUsersList;
    }

    async fetchData() {
      this.rowData = [];
      await accountModule.getReassignmentList();
      const usersData = accountModule.allUsersList;
      // console.log(usersData)
      // usersData = usersData.sort((a, b) => a.name.localeCompare(b.name));
     usersData.forEach(async (user) =>  {
        // const user = usersData[i];
        // eslint-disable-next-line no-await-in-loop
        const userDetails: any = await workflowModule.getUserDetails({ username: user.email.toLowerCase() });
        // eslint-disable-next-line no-await-in-loop
        const userAreaDetails = await workflowModule.getAreasForUser({ username: user.email.toLowerCase() });

        // console.log(userDetails);
        // console.log(userAreaDetails);
        // console.log(userDetails)
        this.rowData.push({
          id: userDetails.user_id,
          name: user.name,
          email: user.email,
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
          adminChartAccess: userDetails.admin_chart_access ? 'Enable' : 'Disable',
          area: userAreaDetails || '',
          defaultPage: userDetails.default_page ? userDetails.default_page : this.getDefaultPage(userDetails.role),
          phoneNumber: user.phone_number,
          active: userDetails.active ? 'Active' : 'InActive',
          userAdminAccess: userDetails.user_admin_access || userDetails.role === 'Engineers' ? 'Enable' : 'Disable',
          username: user.email,
          role: userDetails.role,
          defaultUserFilter: userDetails.default_user_filter || '',
          actions: false,
          team: userDetails.team || '',
          routes: userDetails.routes && JSON.parse(userDetails.routes) ? JSON.parse(userDetails.routes) : '',

        });
      });

      // console.log(this.rowData);
      // this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);

      // await this.sleep(12500);
      // this.dataLoading = false;
      this.rowData.sort((a, b) => a.name.localeCompare(b.name));
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async addRow() {
      if (this.newWellName) {
        this.rowData.push({ Well: this.newWellName });
        this.newWellName = '';
      }
      await this.saveGroupDetailsToBackend();
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }

    @Watch('columnDefs')
    updateRowData(data) {
      if (data) {
        if (data.length > 1 && data[1].field === 'Add Column +' && data[1].pinned === 'left') {
          this.columnDefs[1].pinned = 'right';
        }
      }
    }
}

